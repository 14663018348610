import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { useStyles } from "./Features.styles"
import { Button, Link, Title } from "src/components"
import { featuresItems } from "./Features.items"

export function Features() {
    const classes = useStyles()

    return (
        <section id="features" className={classes.root}>
            <Grid container className={classes.container}>
                <Grid container spacing={8}>

                    <Grid item xs={12}>
                        <Title title="Características" />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {featuresItems.map(({ icon: Icon, title, width }) => (
                                <Grid key={title} item xs={12} md={width}>
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        className={classes.feature}
                                    >
                                        <Grid item>
                                            <Icon />
                                        </Grid>
                                        <Grid item>
                                            <p className={classes.featureTitle}>{title}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} style={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                                <Link
                                    to="https://cdn.prod.website-files.com/64b5bee1a430f0ba47dc7d26/66d0a1bbfa0fd664072f2c8e_FOLLETO%20INFORMATIVO%20Y%20DE%20COMISIONES%20DE%20CR%C3%89DITO%20HIPOTECARIO%20BANCOPPEL.pdf"
                                    className={classes.link}
                                    target="_blank"

                                >
                                    Ver más características
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    Las características de esta sección dependen de cada producto, del análisis crediticio del cliente y de las promociones vigentes al momento de la
                                    contratación. Para más información, consulta el folleto informativo.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.buttonContainer}>
                                <Button
                                    to='/simulador-de-credito-hipotecario'
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                >
                                    Simula tu crédito
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </section>
    )
}