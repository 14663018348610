import React from "react"
import { Grid, Paper, Typography } from "@material-ui/core"
import { useStyles } from "./OurAdvantages.styles"
import { advantagesItems } from "./OutAdvantages.items"
// @ts-ignore  
import LivingRoomImg from "./assets/living-room.png"
import { Title } from "src/components"

export function OurAdvantages() {
    const classes = useStyles()
    return (
        <section className={classes.root}>
            <Grid container className={classes.container}>

                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Title title="Ventajas de tu hipoteca" />
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Grid container spacing={3}>
                            {advantagesItems.map(({ icon: Icon, title, description }) => (
                                <Grid item xs={12} lg={6} key={title}>
                                    <Paper elevation={0} className={classes.paper}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} className={classes.paperImageContainer}>
                                                <Icon />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.paperTitle}> {title} </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.paperDescription}> {description} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <img
                                    src={LivingRoomImg}
                                    alt="Hipoteca"
                                    className={classes.livingRoomImg}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    ¹ La tasa depende de tu perfil crediticio.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    ² Si pagas puntualmente por 12 meses, puedes solicitar no hacer tu siguiente pago moviéndolo al final del plazo del crédito.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </section>
    )
}