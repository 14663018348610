import { GridSize } from "@material-ui/core"
import BatteryIcon from "./assets/bateria.svg"
import MoneyIcon from "./assets/billete.svg"
import CalendarIcon from "./assets/calendar.svg"
import SecureMoneyIcon from "./assets/dinero-seguro.svg"
import DocumentIcon from "./assets/documento.svg"
import HandsIcon from "./assets/manos.svg"
import EPaymentIcon from "./assets/pago-electronico.svg"
import GraphIcon from "./assets/vector.svg"

export const featuresItems: {
    icon: any
    title: string,
    width: GridSize
}[] = [
        {
            icon: HandsIcon,
            title: 'Sin comisión por apertura',
            width: 6,
        },
        {
            icon: GraphIcon,
            title: 'Pagos anticipados sin penalización',
            width: 6,
        },
        {
            icon: BatteryIcon,
            title: 'Te prestamos hasta el 90% del valor del inmueble',
            width: 4
        },
        {
            icon: EPaymentIcon,
            title: 'Esquema de pagos mensuales fijos o ligeros',
            width: 4,
        },
        {
            icon: CalendarIcon,
            title: 'Plazo de 10, 15  y 20 años',
            width: 4,
        },
        {
            icon: SecureMoneyIcon,
            title: 'Seguros de vida y daños incluidos',
            width: 4,
        },
        {
            icon: MoneyIcon,
            title: 'Créditos desde $300 mil hasta $15 millones de pesos',
            width: 4,
        },
        {
            icon: DocumentIcon,
            title: 'Financiamos tus gastos notariales',
            width: 4,
        },
    ]